.App {
  /* display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; */
}

.webview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.script-info-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 60%;
  padding: 40px 20px;
  box-shadow: 0px 1px 4px #e2e2e2;
  border-radius: 4px;
}

.script-info-form > input {
  border: 1px solid #b2b2b2;
  margin-bottom: 10px;
  padding: 10px;
}

.script-info-form > button {
  padding: 15px;
  background-color: #0040e4;
  border: none;
  color: #fff;
  font-weight: 600;
  box-shadow: 2px 2px 2px #e2e2e2;
}

.script-info-form > p {
  color: #616161;
  margin: 0 0 8px 0;
}

.chat-start-btn {
  border: 1px solid #0040e4;
  padding: 15px;
  color: #0040e4;
  background-color: #fff;
  font-weight: 600;
}

.tryagain-btn {
  text-decoration: dotted;
  color: #0040e4;
  font-weight: 700;
  font-size: 12px;
  border-bottom: 1px dashed #0040e4;
  position: absolute;
  top: 10px;
  left: 20px;
}

.disable-btn {
  border-color: #91b0ff;
  border-color: #91b0ff;
  color: #91b0ff;
}

.error-msg {
  color: red;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.chatstart-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100px;
  padding: 20px;
}